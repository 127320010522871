import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin'
import { onAuthStateChanged, getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { getFirestore, doc, getDoc } from 'firebase/firestore/lite'

import { firebaseApp } from '../firebase_app'

const db = getFirestore(firebaseApp)
const authApp = getAuth(firebaseApp)

const baseConfig = {
  userProfilePath: 'users',
  userAdminProp: 'isAdmin',
  localStorageTokenName: 'token',
  localStorageRoleId: 'roleId',
  permissionsCollection: 'roles',
  permissions: 'permissions',
  localStorageUserEmail: ''
}

const handleAuthStateChange = async (auth, config) => {
  if (auth) {
    const docRef = doc(db, 'users', auth.user.uid)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      let profile = docSnap.data()
      if (profile) {
        const firebaseToken = await auth.user.getIdToken()
        let user = { auth, profile, firebaseToken }
        try {
          localStorage.setItem(config.localStorageUserEmail, profile.email.address)
        } catch (e) {}
        localStorage.setItem(config.localStorageTokenName, firebaseToken)
        localStorage.setItem(config.localStorageRoleId, profile.role)
        return user
      } else {
        authApp.signOut()
        localStorage.removeItem(config.localStorageTokenName)
        localStorage.removeItem(config.localStorageRoleId)
        throw new Error('sign_in_error')
      }
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!')
    }
  } else {
    localStorage.removeItem(config.localStorageTokenName)
    throw new Error('sign_in_error')
  }
}

const permissions = async roleId => {
  const docRef = doc(db, 'roles', roleId)
  const docSnap = await getDoc(docRef)
  return docSnap.data()
}

export default () => {
  const config = baseConfig
  const firebaseLoaded = () =>
    new Promise(resolve => {
      onAuthStateChanged(authApp, resolve)
    })

  return async (type, params) => {
    switch (type) {
      case AUTH_LOGIN: {
        const { username, password, alreadySignedIn } = params
        let authC = authApp.currentUser
        if (!authC || !alreadySignedIn) {
          authC = await signInWithEmailAndPassword(authApp, username, password)
        }

        await handleAuthStateChange(authC, config)
        return Promise.resolve()
      }
      case AUTH_LOGOUT: {
        handleAuthStateChange(null, config).catch(() => {})
        localStorage.removeItem(config.localStorageTokenName)
        localStorage.removeItem(config.localStorageRoleId)
        localStorage.removeItem(config.permissions)
        return authApp.signOut()
      }
      case AUTH_CHECK: {
        await firebaseLoaded()

        return localStorage.getItem('token')
          ? Promise.resolve()
          : Promise.reject({ redirectTo: '/login' })
      }
      case AUTH_GET_PERMISSIONS: {
        await firebaseLoaded()

        if (!authApp.currentUser) {
          return Promise.reject()
        }
        const roleId = localStorage.getItem(config.localStorageRoleId)
        const per = await permissions(roleId)
        try {
          delete per.id
          delete per.name
        } catch (error) {}
        localStorage.setItem('permissions', JSON.stringify(per))
        return Promise.resolve(per)
      }
      case AUTH_ERROR: {
        const status = params.status
        if (status === 401 || status === 403) {
          localStorage.removeItem(config.localStorageTokenName)
          localStorage.removeItem(config.localStorageRoleId)
          localStorage.removeItem(config.permissions)
          return Promise.reject()
        }
        return Promise.resolve()
      }

      default: {
        return Promise.resolve()
      }
    }
  }
}
