import edit from './edit'
import list from './list'
import show from './show'
import icon from './icon'
export default {
  list: list,
  edit: edit,
  icon: icon,
  show: show,
  options: { label: 'Usuarios' }
}
