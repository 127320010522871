import React from 'react';
import Icofont from "react-icofont";

const icon =()=> {
    return (
      <div>
        <Icofont icon='icofont-layers' className='icon'></Icofont>
      </div>
    )
  }
  export default icon;