import React from 'react'
//import Icofont from 'react-icofont'
import { FaClipboardList } from 'react-icons/fa'

const icon = () => {
  return (
    <div>
      <FaClipboardList className='icon'></FaClipboardList>
    </div>
  )
}
export default icon
