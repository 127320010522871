import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Login from '../login'
import Register from '../Register'
import ForgotPasswordButton from './CustomForgotPassword'

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://smartrancagua.com/'>
        Corporación de Desarrollo e Innovación de Rancagua&nbsp;
      </Link>{' '}
      {new Date().getFullYear()}
      <p></p>Soporte:
      <Link color='inherit' href='mailto:contacto@smart.rancagua.cl'>
        contacto@smart.rancagua.cl
      </Link>
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  image: {
    // backgroundImage: 'url(/logo_municipalidad_2022.png)',
    background: '#fff',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#ffb31d'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#ffb31d'
  }
}))

const PageCharge = props => {
  if (props.pageInit === 'Login') {
    return <Login PageCharge={PageCharge}></Login>
  } else if (props.pageInit === 'Register') {
    return <Register PageCharge={PageCharge}></Register>
  }
}

function Inicio(props) {
  const [page, setPage] = React.useState('Login')
  const classes = useStyles()

  const ChangeRegister = () => {
    setPage('Register')
  }
  const ChangeLogin = () => {
    setPage('Login')
  }

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}></Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src='/logo_municipalidad_2022.png' alt='logo'></img>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Gestor del Plan de Desarrollo Comunal de Rancagua
          </Typography>

          {page ? <PageCharge pageInit={page}></PageCharge> : ''}

          {page && page === 'Login' ? (
            <Grid container>
              <Grid xs={6} justify='flex-end'>
                <Button
                  type='button'
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={ChangeRegister}
                >
                  No tienes cuenta? Registrate
                </Button>
              </Grid>
              <Grid container xs={6} justify='flex-end'>
                <ForgotPasswordButton {...props} />
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          {page && page === 'Register' ? (
            <Grid container>
              <Grid item>
                <Button
                  type='button'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={ChangeLogin}
                >
                  Ya tienes cuenta? Ingresa
                </Button>
              </Grid>
            </Grid>
          ) : (
            ''
          )}

          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  )
}

export default Inicio
