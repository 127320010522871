import firebase from 'firebase/compat/app'

export const firebaseConfig = {
  apiKey: 'AIzaSyDbQVLaL2GnH4_hS3i3mDTtabTo1HWN04E',
  authDomain: 'pladeco-1.firebaseapp.com',
  databaseURL: 'https://pladeco-1.firebaseio.com',
  projectId: 'pladeco-1',
  storageBucket: 'pladeco-1.appspot.com',
  messagingSenderId: '750733626245',
  appId: '1:750733626245:web:18b81a0510c89c2fa652ec',
  measurementId: 'G-B5155JSPXX'
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)
export const options = {
  app: firebaseApp,
  persistence: 'none',
  lazyLoading: {
    enabled: false
  }
}
