import React from 'react';
import { GoChecklist } from "react-icons/go";


const icon =()=> {
    return (
      <div>
        <GoChecklist className='icon'></GoChecklist>
      </div>
    )
  }
  export default icon;